// extracted by mini-css-extract-plugin
export const fullwidth = "about-module--fullwidth--1H1TS";
export const col1 = "about-module--col1--2s4ac";
export const col2 = "about-module--col2--2ueiH";
export const col3 = "about-module--col3--hbrrD";
export const col4 = "about-module--col4--2pbtd";
export const col5 = "about-module--col5--nCsel";
export const col6 = "about-module--col6--2JmRR";
export const col7 = "about-module--col7--3LxQT";
export const col8 = "about-module--col8--2KDa2";
export const col9 = "about-module--col9--2sGsK";
export const col10 = "about-module--col10--cRDgd";
export const col11 = "about-module--col11--1zIod";
export const gridContact = "about-module--grid-contact--AUBQX";
export const indexImg = "about-module--index--img--1YPl7";
export const h1 = "about-module--h1--1RALD";
export const p = "about-module--p--yya5p";